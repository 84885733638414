import { CDN_DOMAIN } from "../config/cdn"

export default [
    {
        brand: "PIZZA TIME",
        brandLogo: `${CDN_DOMAIN}/static/logos/pizza_time.png`,
        firstname: "Stéphane",
        lastname: "AGAC",
        position: "Fondateur",
        testimonial:
            "« Nous avons le plaisir de travailler avec UnEmplacement.com depuis le début de son ouverture. La plateforme est très simple d'accès ce qui permet de répondre plus facilement à nos besoins en terme de locaux." +
            "<br />De plus elle nous a permis d'accroître notre réseau et surtout d'acquérir à notre enseigne une certaine notoriété. Merci à vous. »"
    },
    {
        brand: "GROUPE SCHMIDT",
        brandLogo: `${CDN_DOMAIN}/static/logos/schmidt.png`,
        firstname: "Alexandre",
        lastname: "GALISSON",
        position: "Responsable Développement",
        testimonial:
            "« Développeur d'enseignes depuis 7 ans, l'approche d'UnEmplacement.com apporte une réelle valeur ajoutée à mon quotidien. En effet, nous ne recevons que des offres en corrélation avec nos recherches réelles, et sur la base des critères qui nous sont propres. J'y gagne en exploitation de mon temps, en qualité de travail et en efficacité car j'ai pu déjà visiter et traiter de nombreux locaux bien en adéquation avec les enseignes que je développe. »"
    },
    {
        brand: "PASCAL COSTE",
        brandLogo: `${CDN_DOMAIN}/static/logos/pascal_coste.png`,
        firstname: "Kevin",
        lastname: "BONINI",
        position: "Responsable Développement",
        testimonial:
            "« Nous travaillons avec UnEmplacement.com depuis plusieurs mois. Le site est fluide, simple, et très pertinent. Nous sommes très content d’avoir la possibilité d’accéder rapidement à des centaines d’offres qui correspondent exactement à nos recherches. De plus cela à largement fait évoluer notre carnet d’adresse, et notre réseau s’en retrouve encore plus étoffé. Je recommande!!! »"
    },
    {
        brand: "MONGOO",
        brandLogo: `${CDN_DOMAIN}/static/logos/mongoo.png`,
        firstname: "Pierre-Yves",
        lastname: "LE BOUDER",
        position: "Responsable Développement",
        testimonial:
            "« En seulement quelques mois, plusieurs dossiers correspondants à mes critères m’ont été proposés. Le fonctionnement est simple & efficace; Exactement le type de service que l’on aime quand on gère une entreprise ! »"
    },
    {
        brand: "BASILIC & CO",
        brandLogo: `${CDN_DOMAIN}/static/logos/basilic_and_co.png`,
        firstname: "Alexis",
        lastname: "BICARD",
        position: "Assistant Réseau Junior",
        testimonial:
            "« Le site UnEmplacement.com est un outil rêvé pour le développement d’enseigne. Il permet de faire connaître notre enseigne et nos recherches auprès des agences immobilières, foncières sans effort. L’annuaire regroupant plus de 1600 personnes permet de prospecter par téléphone sur l’ensemble du territoire pour les recherches les plus urgentes »"
    },
    {
        brand: "DELIVEROO",
        brandLogo: `${CDN_DOMAIN}/static/logos/deliveroo.png`,
        firstname: "Gad",
        lastname: "BERDUGO",
        position: "Responsable Développement",
        testimonial:
            "« Tenant compte de nos contraintes il est confortable de transmettre en masse notre cahier des charges afin de recevoir des offres ciblées à la différence des sites de référencement immobilier qui transmettent des dizaines d'alertes peu pertinentes chaque jour, et, lorsqu'il n'y a plus d'offres, il y en a encore via l'annuaire. En effet au delà des dossiers l'annuaire et donc les rencontres sont intéressantes avec des personnes décisionnelles, actives dans le domaine. Aujourd'hui nous échangeons régulièrement avec le réseau d'UnEmplacement.com sur des dossiers d'actualités ou pour en cibler des futurs. Merci à l'équipe de la plateforme ! »"
    },
    {
        brand: "GROUPE FBH",
        brandLogo: `${CDN_DOMAIN}/static/logos/fbh.png`,
        firstname: "Ferhat",
        lastname: "BOUAZID",
        position: "Responsable Développement",
        testimonial:
            "« UnEmplacement.com est une excellente plateforme qui permet de faire des recherches pour trouver rapidement et facilement des locaux commerciaux d'une part et d'enrichir le portefeuille de partenaires commerciaux d'autre part (grâce à l'annuaire mis en ligne sur le site). Je salue le travail des créateurs de ce site pour sa qualité et le très bon référencement de tous les professionnels de l'immobilier et de la franchise »"
    },
    {
        brand: "DOMINO'S PIZZA",
        brandLogo: `${CDN_DOMAIN}/static/logos/dominos.png`,
        firstname: "Sandrine",
        lastname: "HENG",
        position: "Responsable Développement",
        testimonial:
            "« Grâce à UnEmplacement.com j'ai pu en 1 seul clic communiquer à tous les agents immobiliers mon nouveau secteur de travail qui représente la moitié de la France! Humainement il aurait été impossible d'avoir cette force de frappe. Merci à Kevin et Aurélien qui m'ont permis en quelques semaines de réceptionner des emplacements de qualité pour Domino's Pizza »"
    },
    {
        brand: "BACANA",
        brandLogo: `${CDN_DOMAIN}/static/logos/bacana.png`,
        firstname: "Claudia",
        lastname: "CHOQUET",
        position: "Cofondatrice",
        testimonial:
            "« En tant que restauratrice, je n'ai pas le temps de consulter les sites immobilier de dépôt d'annonces. Avec UnEmplacement.com j'ai reçu en quelques jours des offres correspondant à mes recherches et je négocie en ce moment avec plusieurs agents, mais surtout ... Je concentre mon énergie au développement de mon business et non en prospection. Merci à toute l'équipe »"
    },
    {
        brand: "LES PETITS CHAPERONS ROUGES",
        brandLogo: `${CDN_DOMAIN}/static/logos/les_petits_chaperons_rouges.png`,
        firstname: "Jérémy",
        lastname: "CHENOT",
        position: "Responsable Expansion",
        testimonial:
            "« Cet outil me permet de communiquer et mettre à jour mes recherches au plus grand nombre de professionnels spécialisés en quelques minutes et sans effort. Les dossier envoyés correspondent toujours à mes critères, et le système de réponse est simple et efficace... Un vrai gain de temps »"
    },
    {
        brand: "LDLC",
        brandLogo: `${CDN_DOMAIN}/static/logos/ldlc.png`,
        firstname: "Julien",
        lastname: "SONDAJ",
        position: "Responsable Expansion",
        testimonial:
            "« Un véritable lieu de rencontre 2.0, entre les développeurs et tous les acteurs locaux: les brokers, les foncières/promoteurs et aussi les collectivités territoriales ! Les échanges sont simplifiés avec la prise en compte du cahier des charges de l'enseigne et ses villes ciblées, un annuaire permettant d'interagir avec toute la communauté. Proactif, gain de temps et efficacité, un véritable outil de travail ! »"
    },
    {
        brand: "DOC'BIKER",
        brandLogo: `${CDN_DOMAIN}/static/logos/doc_biker.png`,
        firstname: "Lionel",
        lastname: "BOYAVAL",
        position: "Fondateur",
        testimonial:
            "« Par des développeurs, pour des développeurs ! Aujourd'hui nous travaillons avec UnEmplacement.com depuis plusieurs mois et c'est un plaisir. Instinctif, efficace, et au coeur des problématiques de développement d'enseignes, la recherche de locaux commerciaux est beaucoup plus optimisée avec cette solution.<br />L'équipe qui gère l'outil est à féliciter pour sa réactivité et sa proximité avec les utilisateurs ! »"
    },
    {
        testimonial:
            "« Kevin m’a contacté dès la mise en place du site et je l’utilise depuis sa mise en ligne. C’est un outil pertinent qui nous permet, de manière rapide et efficace, de faire part aux différents interlocuteurs immobiliers des recherches des différentes enseignes avec qui nous sommes sous contrat de développement. De plus, son utilisation permet de développer ses contacts et réseaux professionnels »",
        lastname: "HAHN",
        firstname: "Geoffrey",
        position: "Direction développement pole commerces",
        brand: "PROGRESSIUM",
        brandLogo: `${CDN_DOMAIN}/static/logos/progressium.png`
    },
    {
        testimonial:
            "« UnEmplacement.com un site que nous attendions tous, la recherche de locaux pour notre activité est déterminante, la fluidité et l’efficacité d’UnEmplacement.com révolutionne la manière d’acquérir des locaux. Nous l’utilisons depuis peu mais ressentons déjà son bénéfice dans notre développement »",
        lastname: "BOLLE",
        firstname: "Alexis",
        position: "Chargé de projets",
        brand: "ON AIR",
        brandLogo: `${CDN_DOMAIN}/static/logos/on_air.png`
    },
    {
        testimonial:
            "« J'attendais cette plateforme qui découle du bon sens pour le développement d'une enseigne en Franchise et du métier de l'immobilier. C'est un très bon produit qui me permet au quotidien d'identifier, filtrer et saisir les opportunités qui me paraissent les mieux adaptées, ainsi que les personnes les plus pertinentes pour avancer sur des sujets qui m'intéressent. Je gagne beaucoup de temps et suis très satisfait de cet outil, et je ne vois pas comment je pourrais m'en passer »",
        lastname: "LEFEBVRE",
        firstname: "Valentin",
        position: "Business Developer",
        brand: "GROUPE MOVING",
        brandLogo: `${CDN_DOMAIN}/static/logos/fitness_park.png`
    },
    {
        testimonial:
            "« Nous sommes dans une société où on s’exprime seulement pour nous plaindre donc soyons aussi bavard pour dire quand c’est bien 😊. Cela fait déjà plusieurs mois que j’utilise votre plateforme de mise en relation entre les enseignes et les acteurs de l’immobilier. J’ai tout de suite trouvé intuitif la création pour la prospection, les critères de recherche, je trouvais les interfaces très claires ce qui ne m’a pas lassé pour l’utilisation. Pour faire des modifications sur les choix des villes c’était également très simple et il le faut, car dans un travail de développement il faut pouvoir aller vite. Plus les jours passent et plus je reçois des annonces correspondant à mes besoins. Maintenant c’est au quotidien que je reçois des biens, avec des opportunités vraiment cohérentes avec notre cahier des charges. C’est avec plaisir que je vais aller visiter des locaux grâce à votre plateforme, les échanges avec les agents immobiliers sont très fluides. Je pense que votre plateforme va devenir un réflexe indispensable parmi les outils que j’utilise pour trouver des locaux. Un grand merci pour ce bel outil de travail et cette transparence possible avec des acteurs de l’immobilier de toute la France, qu’il fallait avoir rencontré traditionnellement lors de Salon ou autres rdv physiques »",
        lastname: "GALLOUEDEC",
        firstname: "Pauline",
        position: "Chargée d'expansion",
        brand: "DAY BY DAY MON EPICERIE EN VRAC",
        brandLogo: `${CDN_DOMAIN}/static/logos/day_by_day.jpg`
    },
    {
        testimonial:
            "« Je t’ai dis depuis le début même si je n’ai pas encore trouvé mon bonheur je trouve l’idée géniale d’être connecté aussi rapidement qu’avec des offres de qualités correspondant à notre recherche ! C’est L’immobilier de demain ! »",
        lastname: "SFEZ",
        firstname: "Moise",
        position: "Fondateur",
        brand: "HOMER LOBSTER",
        brandLogo: `${CDN_DOMAIN}/static/logos/homer_lobster.png`
    },
    {
        testimonial:
            "« En quelques jours seulement j’ai été mise en relation avec des experts immobiliers de mon marché, qui m’ont accompagné et aidé à affiner mon cahier des charges. UnEmplacement.com est très intuitif et rassurant lorsque l’on s’attaque à cette fameuse recherche « du local » »",
        lastname: "BOUKHALED",
        firstname: "Sara",
        position: "Fondatrice",
        brand: "MAISON GAZELLE",
        brandLogo: `${CDN_DOMAIN}/static/logos/maison_gazelle.jpg`
    },
    {
        testimonial:
            "« L’outil UnEmplacement.com est devenu incontournable dans ma recherche de locaux. J’ai pu recevoir de nombreuses offres correspondant exactement à mes recherches. De plus, je ne suis pas envahi de mails puisque je choisis ou non d’avoir davantage d’informations sur les offres proposées. Le site est bien construit, ludique et efficace ! L’idée est très bonne et le résultat plus que satisfaisant »",
        lastname: "CLEMENT",
        firstname: "Juliette",
        position: "Chargée d'expansion franchises",
        brand: "ESPRIT",
        brandLogo: `${CDN_DOMAIN}/static/logos/esprit.png`
    },
    {
        testimonial:
            "« Nous avons découvert cette plateforme après plusieurs mois de recherche intense auprès d'agences immobilières. En quelques jours, nous avons reçu des offres beaucoup plus ciblées et pertinentes que toutes celles qui nous étaient parvenues jusqu'ici ! Bref, un outil efficace et simplissime pour un gain de temps énorme ! Merci à Aurélien et Kevin pour cette superbe idée ! »",
        lastname: "COET",
        firstname: "Tiphanie",
        position: "Co-fondatrice",
        brand: "CHEESERS",
        brandLogo: `${CDN_DOMAIN}/static/logos/cheesers.jpg`
    },
    {
        testimonial:
            "« UnEmplacement.com est un outil innovant mais surtout efficace : les offres générées correspondent entièrement au cahier des charges prédéfini. C'est un gain de temps et d'énergie formidable avec une adéquation quasi parfaite entre l'offre et la demande »",
        lastname: "FLIEGANS",
        firstname: "Christophe",
        position: "Directeur",
        brand: "LA FRITUUR",
        brandLogo: `${CDN_DOMAIN}/static/logos/la_frituur.png`
    },
    {
        testimonial:
            "« UnEmplacement.com c’est tout simplement un outil Premium. Je suis avec eux depuis le début de l’aventure, j’y ai cru, j’y crois encore et faut continuer à les soutenir. C’est un travail important que de trier les offres et de recevoir que des dossiers tops…Unemplacement.com le fait pour vous Génial. La base de données offres est superbe ;  l’annuaire permet d’échanger intelligemment avec les autres enseignes pour des projets communs de création ou de cession.  Enfin, Aurélien et Kevin ont réussi à allier professionnalisme et détente autour des petits déjeuners…qui sont devenus, en quelques mois, incontournables dans l’immobilier commercial. Bonne continuation, plein de réussite et à très vite pour échanger »",
        lastname: "GAQUIERE",
        firstname: "Dimitri",
        position: "Responsable développement",
        brand: "BOULANGERIE LOUISE",
        brandLogo: `${CDN_DOMAIN}/static/logos/boulangerie_louise.png`
    },
    {
        testimonial:
            "« J’utilise le site UnEmplacement.com depuis quelques mois, tout d’abord la prise en main, en quelques minutes ma demande était en ligne. L’atout majeur du site, le carnet d’adresse. On est mis en relation avec de nombreux professionnels du domaine dont on ignore souvent l’existence, nous permettant de rayonner en dehors du cadre local. Un travail en volume de qualité »",
        lastname: "VALENTIN",
        firstname: "Thierry",
        position: "Franchisé",
        brand: "SOPHIE LEBREUILLY",
        brandLogo: `${CDN_DOMAIN}/static/logos/sophie_lebreuilly.jpg`
    },
    {
        testimonial:
            "« Une plateforme fluide et simple d’utilisation qui permet la transmission rapide du cahier des charges de l’enseigne et des zones de recherches.Des retours immédiatement visibles via une alerte mail, ET  avec une historisation des échanges avec les correspondants. Plus une grande réactivité  de l’équipe … A recommander ! »",
        lastname: "BOUSSIRA",
        firstname: "Daniel",
        position: "Directeur général",
        brand: "CARREMENT FLEURS",
        brandLogo: `${CDN_DOMAIN}/static/logos/carrement_fleurs.jpg`
    },
    {
        testimonial:
            "« Je travaille avec UnEmplacement.com depuis son lancement. J'apprécie particulièrement leur système d'offres ciblées, qui permet d'avoir des offres répondant à mon cahier des charges. Arrivé récemment sur mon secteur, le site m'a permis de créer rapidement un réseau et d'étoffer mon carnet d'adresses. »",
        lastname: "THOMAS",
        firstname: "Gregory",
        position: "Responsable développement",
        brand: "CARREFOUR PROXIMITE",
        brandLogo: `${CDN_DOMAIN}/static/logos/carrefour_proximite.jpg`
    },
    {
        testimonial:
            "« Nous utilisons UnEmplacement.com depuis le début de notre activité. Nous recommandons cet outil innovant, simple d’utilisation et intuitif.Aurélien et Kevin sont toujours à l’écoute et très réactifs, un réel plus pour le développement de leur activité, bravo ! »",
        lastname: "MADELEINE",
        firstname: "Christelle",
        position: "Directrice développement",
        brand: "FRESH AU SERVICE DU GOUT",
        brandLogo: `${CDN_DOMAIN}/static/logos/fresh.png`
    },
    {
        testimonial:
            "« J'utilise l'outil depuis quelques mois. Son principal avantage est qu'il permet de faciliter et de fluidifier les échanges avec les différents apporteurs d'affaires et augmente notre capacité à traiter les nombreuses opportunités d'implantations.De plus, la possibilité d'avoir pu saisir sur le site un cahier des charges précis en termes de secteur de recherche, de surface et de type d'emplacement, permet de recevoir des offres ciblées. Un seul mot : BRAVO pour le développement de cet outil ! »",
        lastname: "MORAUT",
        firstname: "Frédéric",
        position: "Responsable développement",
        brand: "KRYS GROUPE",
        brandLogo: `${CDN_DOMAIN}/static/logos/krys.png`
    }
]
