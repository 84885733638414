import React, { Component } from "react"
import Router from "next/router"
import _ from "lodash"
import classNames from "classnames"

import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import {
    withStyles,
    MuiThemeProvider,
    createMuiTheme
} from "@material-ui/core/styles"

import types from "../datas/prospectionMapTypes"


const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginBottom: 40,
        width: 380
    },
    group: {
        margin: `${theme.spacing(1)}px 0`
    }
})

const theme = createMuiTheme({
    overrides: {
        // Name of the component ⚛️ / style sheet
        MuiButton: {
            // Name of the rule
            root: {
                // Some CSS
                background: "#0db3ac",
                borderRadius: 3,
                border: 0,
                color: "white",
                height: 45,
                padding: "0 15px",
                fontFamily: "Lato, Roboto, Helvetica, Arial, sans-serif",
                fontWeight: 800,
                letterSpacing: 0,
                "&:hover": {
                    backgroundColor: "#09a59f"
                }
            },
            label: {
                fontFamily: "Lato, Roboto, Helvetica, Arial, sans-serif",
                fontWeight: 800,
                color: "white"
            }
        }
    }
})

class BrandSubscribeFormV2 extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedType: null,
            hoverType: null
        }
    }

    validateForm = () => {
        if (!this.state.selectedType) {
            return
        }

        const redirectTypes = {
            1: 'onboarding-local-commercial',
            3: 'onboarding-pop-up-store',
            4: 'onboarding-terrain',
            5: 'onboarding-bureaux',
            7: 'onboarding-entrepots',
            8: 'onboarding-investissement',
            9: 'onboarding-fonds-de-commerce'
        }

        window.location = `/${redirectTypes[this.state.selectedType]}`
    }

    resetError = key => {
        if (this.state.error[key]) {
            this.setState({ error: { ...this.state.error, [key]: undefined } })
        }
    }

    render() {
        const { classes } = this.props

        return (
            <form className="form-brand-subscribe-block">
                <Paper
                    className={classNames(classes.root, "brand-subscribe-block")}
                    elevation={1}
                >
                    <p
                        style={{
                            fontFamily:
                                "Lato, Roboto, Helvetica, Arial, sans-serif",
                            fontSize: 24,
                            fontWeight: 800,
                            color: "#0cb2ae",
                            lineHeight: 1,
                            textAlign: "center",
                            marginTop: 10,
                            marginBottom: 30
                        }}
                    >
                        Que recherchez-vous ?
                    </p>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap"
                        }}
                    >
                        {types
                            .filter(t => !t.hidden)
                            .map(type => {
                                return (
                                    <div
                                        style={{
                                            width: 95,
                                            height: 100,
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                            background:
                                                (
                                                    this.state.hoverType &&
                                                    this.state.hoverType === type.prospection_type
                                                ) ||
                                                (
                                                    this.state.selectedType &&
                                                    this.state.selectedType === type.prospection_type
                                                ) ?
                                                    'rgba(13, 179, 171, 0.05)' : 'none',
                                            paddingTop: 5,
                                            paddingLeft: 2.5,
                                            paddingRight: 2.5,
                                            paddingBottom: 0,
                                            borderRadius: 5,
                                            margin: 2
                                        }}
                                        onMouseOver={event => {
                                            this.setState({ hoverType: type.prospection_type })
                                        }}
                                        onMouseOut={event => {
                                            this.setState({ hoverType: null })
                                        }}
                                        onClick={event => {
                                            this.setState({ selectedType: type.prospection_type })
                                        }}
                                    >
                                        <div
                                            style={{
                                                border: `1px dashed ${
                                                    (
                                                        this.state.hoverType &&
                                                        this.state.hoverType === type.prospection_type
                                                    ) ||
                                                    (
                                                        this.state.selectedType &&
                                                        this.state.selectedType === type.prospection_type
                                                    ) ? '#0db3ac' : 'black'}`,
                                                borderRadius: 5,
                                                display: "inline-block",
                                                margin: "auto",
                                                width: 55,
                                                height: 55
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                {type
                                                    .icon(
                                                        50,
                                                        50,
                                                        2,
                                                        (
                                                            this.state.hoverType &&
                                                            this.state.hoverType === type.prospection_type
                                                        ) ||
                                                        (
                                                            this.state.selectedType &&
                                                            this.state.selectedType === type.prospection_type
                                                        )  ? undefined : 'black'
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                fontSize: 10,
                                                color:
                                                    (
                                                        this.state.hoverType &&
                                                        this.state.hoverType === type.prospection_type
                                                    ) ||
                                                    (
                                                        this.state.selectedType &&
                                                        this.state.selectedType === type.prospection_type
                                                    ) ? '#0db3ac' : 'black',
                                                marginTop: 10,
                                                marginBottom: 10,
                                                fontWeight: 600,
                                                textAlign: "center",
                                                alignItems: "center",
                                                height: 20
                                            }}
                                        >
                                            {type.label}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="row" style={{ marginTop: 10 }}>
                        <div className="col" style={{ margin: 0, padding: 0 }}>
                            <div
                                style={{
                                    width: "100%",
                                    textAlign: "center"
                                }}
                            >
                                <MuiThemeProvider theme={theme}>
                                    <Button
                                        style={{
                                            width: "95%",
                                            margin: "auto",
                                            border: 0
                                        }}
                                        onClick={this.validateForm}
                                        disableFocusRipple
                                        disableRipple
                                    >
                                        <span
                                            style={{ color: "white" }}
                                        >
                                            Envoyer ma recherche
                                        </span>
                                    </Button>
                                </MuiThemeProvider>
                            </div>
                        </div>
                    </div>
                </Paper>
            </form>
        )
    }
}

export default withStyles(styles)(BrandSubscribeFormV2)
