import React, { Component } from "react"
import { Carousel } from "react-responsive-carousel"
import Fade from "react-reveal/Fade"

import { CDN_DOMAIN } from "../config/cdn"

const press = [
    {
        logo: `${CDN_DOMAIN}/static/logos_testimonials/lefigaro_grey.png`,
        url: `${CDN_DOMAIN}/static/testimonials/figaro.pdf`,
        name: "Le Figaro"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_testimonials/business_immo_grey.png`,
        url: `${CDN_DOMAIN}/static/testimonials/business_immo.pdf`,
        name: "Business Immo"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_testimonials/capital_grey.png`,
        url: `${CDN_DOMAIN}/static/testimonials/capital.pdf`,
        name: "Capital"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_testimonials/challenges_grey.png`,
        url: `${CDN_DOMAIN}/static/testimonials/challenges.pdf`,
        name: "Challenges"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_testimonials/forbes.png`,
        url: `${CDN_DOMAIN}/static/testimonials/forbes.pdf`,
        name: "Forbes"
    },
    /*
    {
        logo: `${CDN_DOMAIN}/static/logos_testimonials/la_tribune_grey.png`,
        url: `${CDN_DOMAIN}/static/testimonials/la_tribune.pdf`,
        name: "La Tribune"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_testimonials/les_echos_solutions_grey.png`,
        url: `${CDN_DOMAIN}/static/testimonials/les_echos_solutions.pdf`,
        name: "Les Echos Solutions"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_testimonials/figaro_immo_grey.png`,
        url: `${CDN_DOMAIN}/static/testimonials/figaro_immo.pdf`,
        name: "Le Figaro Immobilier"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_testimonials/fnaim_grey.png`,
        url: `${CDN_DOMAIN}/static/testimonials/fnaim.pdf`,
        name: "La Fédération Nationale de l'Immobilier",
        disableWidth: true,
        height: 100
    },
    /*,
    {
        logo: `${CDN_DOMAIN}/static/logos_testimonials/snacking.png`,
        url: `${CDN_DOMAIN}/static/testimonials/snacking.pdf`,
        name: "Snacking"
    }
    ,
    {
        logo: `${CDN_DOMAIN}/static/logos_testimonials/toute_la_franchise.png`,
        url: `${CDN_DOMAIN}/static/testimonials/toute_la_franchise.pdf`,
        name: "Snacking"
    }*/
]

class PressRelease extends Component {
    render() {
        return (
            <section
                className="press row container-fluid mr-md-custom2"
            >
                <div className="hideDesktop">
                    <h2
                        style={{
                            fontFamily:
                                "Lato, Roboto, Helvetica, Arial, sans-serif",
                            color: "#0db3ac",
                            margin: "auto",
                            textAlign: "center",
                            fontWeight: 900,
                            fontSize: 22,
                            marginTop: 0
                        }}
                    >
                        Ils parlent de nous
                    </h2>
                    <Fade>
                        <Carousel
                            showThumbs={false}
                            showArrows={false}
                            showStatus={false}
                        >
                            {press.map((press, i) => (
                                <div
                                    key={i}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                    <a target="_blank" href={press.url}>
                                        <img
                                            src={press.logo}
                                            style={{
                                                width: 150,
                                                marginLeft: 20,
                                                marginRight: 20,
                                                userSelect: "none"
                                            }}
                                            alt={press.name}
                                            title={press.name}
                                        />
                                    </a>
                                </div>
                            ))}
                        </Carousel>
                    </Fade>
                </div>
                <div className="hideForm" style={{ margin: "auto" }}>
                    <h2
                        style={{
                            fontFamily:
                                "Lato, Roboto, Helvetica, Arial, sans-serif",
                            color: "#0db3ac",
                            margin: "auto",
                            textAlign: "center",
                            fontWeight: 900,
                            fontSize: 22,
                            marginTop: 20
                        }}
                    >
                        Ils parlent de nous
                    </h2>
                    <div style={{ textAlign: "center" }}>
                        {press.map((press, i) => (
                            <a target="_blank" href={press.url}>
                                <Fade>
                                    <img
                                        src={press.logo}
                                        style={{
                                            width: !press.disableWidth ? 125 : undefined,
                                            height: press.height ? press.height : undefined,
                                            marginLeft: 20,
                                            marginRight: 20,
                                            userSelect: "none"
                                        }}
                                        alt={press.name}
                                        title={press.name}
                                    />
                                </Fade>
                            </a>
                        ))}
                    </div>
                </div>
            </section>
        )
    }
}


export default PressRelease
