import React, { Component, Fragment } from "react"
import BrandSubscribeFormV2 from "../components/BrandSubscribeFormV2"
import { Carousel } from "react-responsive-carousel"
import { responsiveWrapper } from "react-responsive-redux"

import kpis from "../datas/kpis"
import { CDN_DOMAIN } from "../config/cdn"

class MainBlock extends Component {
    render() {
        const HideDesktop = responsiveWrapper({ query: `(max-width: 767px)` })

        return (
            <Fragment>
                <div
                    style={{
                        textAlign: "left",
                        letterSpacing: "0.1",
                        width: this.props.width,
                        margin: "auto",
                        marginTop: this.props.marginTop,
                        minHeight: this.props.minHeight
                    }}
                >
                    <div
                        className="row"
                        style={{
                            margin: 0,
                            paddingRight: this.props.paddingRight,
                            minHeight: this.props.minHeight
                        }}
                    >
                        <div
                            className="col-12 col-sm-12 col-md-6 padding-introduction"
                            style={{
                                paddingLeft: 0,
                                paddingRight: 0,
                                minHeight: this.props.minHeight
                            }}
                        >
                            <div className="introduction-block">
                                <h1
                                    style={{
                                        fontFamily:
                                            "Lato, Roboto, Helvetica, Arial, sans-serif",
                                        fontSize: this.props.fontSize,
                                        fontWeight: 900,
                                        color: this.props.color1 || "black",
                                        lineHeight: 1,
                                        maxWidth: "90%",
                                        fontSize: this.props.size1 || 27,
                                        maxWidth: 500,
                                        textAlign:
                                            this.props.textAlign || "left",
                                        margin: this.props.marginTitleAuto
                                            ? "auto"
                                            : 0
                                    }}
                                >
                                    Accédez aux meilleurs<br />
                                    emplacements
                                </h1>
                                <p
                                    style={{
                                        fontFamily:
                                            "Lato, Roboto, Helvetica, Arial, sans-serif",
                                        fontSize: this.props.size2 || 14,
                                        fontWeight: 400,
                                        color: this.props.color2 || "black",
                                        lineHeight: 1.4,
                                        maxWidth: 400,
                                        textAlign:
                                            this.props.textAlign || "left",
                                        margin: this.props.marginTitleAuto
                                            ? "auto"
                                            : 0,
                                        marginTop: 20,
                                        marginBottom: this.props.marginBottom3
                                    }}
                                >
                                    Communiquez votre recherche de locaux à plus de 500 sociétés spécialisées en immobilier d’entreprise, et <b>recevez des dossiers exclusifs et confidentiels, qui correspondent à vos critères</b>.
                                </p>
                                <p
                                    style={{
                                        fontFamily:
                                            "Lato, Roboto, Helvetica, Arial, sans-serif",
                                        fontSize: this.props.size2 || 14,
                                        fontWeight: 400,
                                        color: this.props.color2 || "black",
                                        lineHeight: 1.4,
                                        maxWidth: 400,
                                        textAlign:
                                            this.props.textAlign || "left",
                                        margin: this.props.marginTitleAuto
                                            ? "auto"
                                            : 0,
                                        marginTop: 10,
                                        marginBottom: this.props.marginBottom3
                                    }}
                                >
                                    Un service 100% gratuit, sans commission.
                                </p>
                                <div className="hideForm">
                                    <div
                                        style={{
                                            fontFamily:
                                                "Lato, Roboto, Helvetica, Arial, sans-serif",
                                            color: "black",
                                            fontWeight: 400,
                                            fontSize: 13,
                                            background:
                                                "rgba(255, 255, 255, 0.95)",
                                            borderRadius: 5,
                                            padding: 10,
                                            textShadow: "none",
                                            maxWidth: 395,
                                            marginTop: 15
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontSize: 14,
                                                lineHeight: 1.5
                                            }}
                                        >
                                            “Enfin un site qui me propose
                                            régulièrement des offres de qualité
                                            qui correspondent VRAIMENT à mes
                                            critères. Merci.”
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                marginTop: 5
                                            }}
                                        >
                                            <div>
                                                <img
                                                    src={`${CDN_DOMAIN}/static/deliveroo.png`}
                                                    alt="Deliveroo"
                                                    style={{
                                                        width: 50,
                                                        height: 50,
                                                        padding: 0
                                                    }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    marginLeft: 5,
                                                    fontWeight: 700,
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignContent:
                                                        "space-between",
                                                    justifyContent:
                                                        "space-evenly"
                                                }}
                                            >
                                                <div
                                                    style={{ fontWeight: 700 }}
                                                >
                                                    Gad BERDUGO,{" "}
                                                </div>
                                                <div
                                                    style={{ fontWeight: 500 }}
                                                >
                                                    Responsable Développement
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <HideDesktop>
                                    <div className="kpis">
                                        <Carousel
                                            showThumbs={false}
                                            showArrows={false}
                                            showStatus={false}
                                        >
                                            {kpis.map((entry, i) => (
                                                <div
                                                    key={i}
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignContent: "center",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            fontFamily:
                                                                "Lato, Roboto, Helvetica, Arial, sans-serif",
                                                            color: "#0db3ac",
                                                            fontWeight: 900,
                                                            fontSize: 32,
                                                            marginBottom: 10,
                                                            marginTop: 30
                                                        }}
                                                    >
                                                        {entry.value}
                                                    </div>
                                                    <div
                                                        style={{
                                                            fontFamily:
                                                                "Lato, Roboto, Helvetica, Arial, sans-serif",
                                                            color: "black",
                                                            fontWeight: 500,
                                                            fontSize: 20,
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        {entry.unit}
                                                    </div>
                                                </div>
                                            ))}
                                        </Carousel>
                                    </div>
                                </HideDesktop>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 form-col">
                            <BrandSubscribeFormV2 />
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default MainBlock
