export default [
    {
        value: "200 000",
        unit: "Dossiers échangés"
    },
    {
        value: "700 000",
        unit: "Contacts téléphoniques"
    },
    {
        value: "5 000",
        unit: "Enseignes & Indépendants"
    },
    {
        value: "2 000",
        unit: "Agents immobiliers & Foncières"
    }/*,
    {
        value: "180",
        unit: "Collectivités territoriales"
    }*/
]
